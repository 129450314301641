import React, { useEffect } from "react";

import BarComparison from "$components/charts/bar-comparison";
import ChartPie from "$components/charts/pie";
import ExistingProjects from "./components/existing-projects";
import ExternalLegend from "$components/charts/legend/external";
import PercentLegend from "$components/charts/legend/components/percent-legend";

import { useDocumentTitle } from "$utils/document-title";
import { getTerm } from "$stores/dictionary";
import { ChartsStore } from "$stores/charts";
import { aggregateChildren, limitChartData } from "../clients/lib/chart-helper";
import { LabelPercentTooltip } from "$components/charts/tooltips/currency";
import { setHelpContent } from "$stores/help";
import { HELP_TEAM_PAST_WORK } from "../help/lib/help-contents";
import { scrollToName } from "$utils/scroll-to";
import { setFilters } from "$stores/employeeExistingProjects.js";

const TeamPastWork = () => {
    useDocumentTitle("Employee Past Work");

    const { employeeHoursByClient, employeeHoursByClientGroup, employeeHoursByIndustry, employeeHoursByProjectType, employeeHoursByServiceLine, employeeHoursByProject } = ChartsStore.useState((s) => {
        return {
            employeeHoursByClient: limitChartData(s.charts["employeeHoursByClient"], 4),
            employeeHoursByClientGroup: limitChartData(s.charts["employeeHoursByClientGroup"], 4),
            employeeHoursByIndustry: limitChartData(s.charts["employeeHoursByIndustry"], 4),
            employeeHoursByProjectType: limitChartData(s.charts["employeeHoursByProjectType"], 4),
            employeeHoursByServiceLine: limitChartData(s.charts["employeeHoursByServiceLine"], 9),
            employeeHoursByProject: limitChartData(s.charts["employeeHoursByProject"], 5),
        };
    });

    useEffect(() => {
        setHelpContent(HELP_TEAM_PAST_WORK);
    }, []);

    const setExistingProjectFilter = (id, value) => {
        setFilters({
            [id]: value,
        });

        scrollToName("existing-projects");
    };

    return (
        <div>
            <h1>
                <em>Past</em> Work
            </h1>

            <div className="dashboard-cards">
                <div className="card -span2">
                    <h4 className="txt -center -white">
                        <em>Time Spent</em> By {getTerm("parentClient")} & {getTerm("subClient")}
                    </h4>

                    <div className="chart-legend">
                        <ChartPie
                            charts={[
                                {
                                    data: employeeHoursByClientGroup,
                                    outerRadius: 105,
                                    innerRadius: 50,
                                    onClick: (data) => {
                                        setExistingProjectFilter("clientGroupName", data.label);
                                    },
                                },
                                {
                                    data: aggregateChildren(employeeHoursByClientGroup),
                                    outerRadius: 170,
                                    innerRadius: 115,
                                    onClick: (data) => {
                                        setExistingProjectFilter("clientName", data.label);
                                    },
                                },
                            ]}
                            TooltipComponent={LabelPercentTooltip}
                        />
                        <ExternalLegend
                            data={employeeHoursByClientGroup}
                            Component={PercentLegend}
                            onClick={(data) => {
                                setExistingProjectFilter("clientGroupName", data.label);
                            }}
                        />
                    </div>
                </div>

                <div className="card -span2">
                    <h4 className="txt -center -white">
                        <em>Time Spent</em> By {getTerm("serviceLine")} & {getTerm("projectType")}
                    </h4>

                    <div className="chart-legend">
                        <ChartPie
                            charts={[
                                {
                                    data: employeeHoursByServiceLine,
                                    outerRadius: 105,
                                    innerRadius: 50,
                                    onClick: (data) => {
                                        setExistingProjectFilter("serviceLine", [{ text: data.label, value: data.labelId }]);
                                    },
                                },
                                {
                                    data: aggregateChildren(employeeHoursByServiceLine),
                                    outerRadius: 170,
                                    innerRadius: 115,
                                    onClick: (data) => {
                                        setExistingProjectFilter("projectType", data.label);
                                    },
                                },
                            ]}
                            TooltipComponent={LabelPercentTooltip}
                        />
                        <ExternalLegend
                            data={employeeHoursByServiceLine}
                            Component={PercentLegend}
                            onClick={(data) => {
                                setExistingProjectFilter("serviceLine", [{ text: data.label, value: data.labelId }]);
                            }}
                        />
                    </div>
                </div>

                <div className="card -span2">
                    <h4 className="txt -center -white">
                        <em>Time Spent</em> By {getTerm("industry")}
                    </h4>

                    <div className="chart-legend">
                        <ChartPie
                            charts={[
                                {
                                    data: employeeHoursByIndustry,
                                    outerRadius: 105,
                                    innerRadius: 50,
                                    onClick: (data) => {
                                        setExistingProjectFilter("industry", data.label);
                                    },
                                },
                            ]}
                            TooltipComponent={LabelPercentTooltip}
                        />
                        <ExternalLegend
                            data={employeeHoursByIndustry}
                            Component={PercentLegend}
                            onClick={(data) => {
                                setExistingProjectFilter("industry", data.label);
                            }}
                        />
                    </div>
                </div>

                <div className="card -span2">
                    <h4 className="txt -center -white">
                        <em>Time Spent By</em> {getTerm("existingProject")}
                    </h4>

                    <div className="chart-legend">
                        <BarComparison
                            data={employeeHoursByProject}
                            preSup=""
                            postSup="%"
                            modifyFn={(value) => value.toFixed(1)}
                            onClick={(data) => {
                                setExistingProjectFilter("name", data.text);
                            }}
                        />
                    </div>
                </div>
            </div>
            <br />
            <br />

            <div className="flex-header">
                <h3>
                    <em>Associated</em> {getTerm("existingProjects")}
                </h3>
                {/*<h4 className="txt -right -no-margin">
                    <span className="memo">* Billings under 10 hours are omitted</span>
                </h4>*/}
            </div>
            <div name="existing-projects">
                <ExistingProjects />
            </div>
        </div>
    );
};

export default TeamPastWork;
