import _ from "lodash";
import debounce from "debounce";
import { useEffect, useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AppContextStore } from "$stores/app-context";
import { handleAbort } from "$services/api";

export function usePaginatedStoreWrapper(store, options) {
    const { fetchList, reset, setPagingInfo, setPageIndex, setPerPage, setSelectedData, setHiddenColumns, keyPrefix = "tableFilter." } = options;
    const { hasLoadedFilters, globalFilters } = AppContextStore.useState((f) => {
        return { hasLoadedFilters: f.hasLoadedFilters, globalFilters: f.currentFilters, overviewLoading: f.overviewLoading };
    });

    const [controllerList, setControllerList] = useState(null);

    const [searchParams] = useSearchParams();

    const storeState = store.useState();

    const sendRequest = useCallback(
        (fetchList, globalFilters, storeState) => {
            if (controllerList) {
                handleAbort([controllerList]);
            }
            var controller = fetchList(
                { ...(options?.suppressGlobalFilters ? { ...(options?.overrideGlobalFilters || {}) } : globalFilters || { ...(options?.overrideGlobalFilters || {}) }) },
                { keyPrefix, ...storeState, ...(options?.overrideFilters ? { filters: { ...(storeState?.filters || {}), ...options?.overrideFilters } } : {}) }
            );
            setControllerList(controller);
        },
        [options]
    );
    const debouncedFetch = useMemo(() => {
        return debounce(sendRequest, 600);
    }, [sendRequest]);

    useEffect(() => {
        return () => {
            if (reset) reset();
        };
    }, [reset]);

    useEffect(() => {
        if (hasLoadedFilters) {
            const updatables = {};
            const sort = JSON.parse(searchParams.get("sort"));
            const filter = JSON.parse(searchParams.get("filter"));
            const pageIndex = searchParams.get("pageIndex");
            const pageSize = searchParams.get("pageSize");

            if (sort) updatables.sort = sort;
            if (filter) updatables.filter = filter;
            if (pageIndex) updatables.pageIndex = parseInt(pageIndex, 10);
            if (pageSize) updatables.pageSize = parseInt(pageSize, 10);
            if (!_.isEmpty(updatables)) {
                setPagingInfo(updatables);
            }
        }
    }, [hasLoadedFilters, searchParams, setPagingInfo]);

    useEffect(() => {
        if (hasLoadedFilters) {
            debouncedFetch(fetchList, globalFilters, storeState);
        }
    }, [hasLoadedFilters, globalFilters, fetchList, storeState.filters, storeState.pageIndex, storeState.pageSize, storeState.sort]);

    return useMemo(
        () => ({
            ...storeState,
            fetchList,
            reset,
            setPagingInfo,
            setPageIndex,
            setPerPage,
            setSelectedData,
            setHiddenColumns,
        }),
        [storeState]
    );
}
