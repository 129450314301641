import React from "react";

import DateCell from "$components/table/cells/date.js";
import Flags from "$components/flags";
import InitialsCell from "$components/table/cells/initials";
import LargeNumber from "$components/info-list/content/large-number";
import LogoCell from "$components/table/cells/logo";

import { abbrNum } from "$utils/number-helper";
import { getTerm } from "$stores/dictionary";
import { DateFilter, MultiRangeFilter, SelectColumnFilter } from "$components/table/components/filters/filter";
import CollapsingPills from "$components/table/cells/collapsing-pills";

export const buildDefaultColumns = ({ visibleColumns = null, filterOptions, globalFilterRanges }) => {
    var columns = [
        {
            Header: () => "Name",
            accessorKey: "name", // accessor is the "key" in the data
            id: "name", // id is used as a sort key (i.e., actual api name)
            Cell: ({ data, value }) => (
                <div>
                    <Flags data={[{ classnames: { "-independence": data.requiresIndependence }, label: "Check Independence" }]} />
                    {value}
                </div>
            ),
        },
        {
            Header: () => getTerm("projectType"),
            accessorKey: "projectType", // accessor is the "key" in the data
            id: "projectType", // id is used as a sort key (i.e., actual api name)
            Cell: ({ data, value }) => (
                <div>
                    <Flags data={[{ classnames: { "-independence": data.requiresIndependence }, label: "Check Independence" }]} />
                    {value}
                </div>
            ),
        },
        {
            Header: () => getTerm("projectTypeGroup"),
            accessorKey: "projectTypeGroup", // accessor is the "key" in the data
            id: "projectTypeGroup", // id is used as a sort key (i.e., actual api name)
            size: 120,
        },
        {
            Header: () => getTerm("parentClient"),
            accessorKey: "clientGroupName",
            id: "clientGroupName",
            Cell: ({ data, value }) => <LogoCell value={value} logoUrl={""} />, //TODO
        },
        {
            Header: () => getTerm("subClient"),
            accessorKey: "clientName",
            id: "clientName",
            className: "",
        },
        {
            Header: () => getTerm("industry"),
            accessorKey: "industry",
            size: 120,
            enableSorting: false,
            Cell: ({ data, value }) => {
                const newData = value ? [{ id: value, text: value }] : [];
                return <CollapsingPills data={newData} maxVisible={2} keyPrefix="industry" analyticsLabel={getTerm("industries")} />;
            },
        },
        {
            Header: () => getTerm("serviceLine"),
            accessorKey: "serviceLine",
            size: 120,
            enableSorting: false,
            Filter: SelectColumnFilter,
            filterOptions: { options: filterOptions?.practice?.values },
            Cell: ({ data, value }) => {
                const newData = value ? [{ id: value, text: value }] : [];
                return <CollapsingPills data={newData} maxVisible={2} keyPrefix="practice" analyticsLabel={getTerm("serviceLines")} />;
            },
        },
        {
            Header: () => getTerm("projectPIC"),
            accessorKey: "projectPicName",
            id: "projectPicName",
            size: 100,
            Cell: ({ data, value }) => <InitialsCell value={data.projectPic} isActive={data.projectPicIsActive} />,
        },
        {
            Header: () => getTerm("existingProjectStart"),
            accessorKey: "startDate",
            id: "startDate",
            sortDescFirst: true,
            size: 100,
            enableColumnFilter: false,
            Filter: DateFilter,
            filterOptions: { label: "Date" },
            Cell: ({ data, value }) => <DateCell value={value} />,
        },
        {
            Header: () => getTerm("existingProjectEnd"),
            accessorKey: "endDate",
            id: "endDate",
            sortDescFirst: true,
            size: 100,
            enableColumnFilter: false,
            Filter: DateFilter,
            filterOptions: { label: "Date" },
            Cell: ({ data, value }) => <DateCell value={value} noValue="Ongoing" />,
        },
        {
            Header: () => "Status",
            accessorKey: "status",
            id: "projectstatus",
            sortDescFirst: true,
            size: 100,
            Filter: SelectColumnFilter,
            filterOptions: { options: globalFilterRanges?.status || [] },
        },
        {
            Header: () => getTerm("revenueTD"),
            accessorKey: "revenueToDate",
            id: "revenueToDate",
            sortDescFirst: true,
            size: 140,
            Filter: MultiRangeFilter,
            filters: {
                min: globalFilterRanges?.revenueToDateMin ? Math.floor(globalFilterRanges?.revenueToDateMin) : "",
                max: globalFilterRanges?.revenueToDateMax ? Math.ceil(globalFilterRanges?.revenueToDateMax) : "",
                format: { isCurrency: true },
            },
            filterOptions: {
                cssClass: "-right",
            },
            Cell: ({ data, value }) => {
                return <LargeNumber value={`$${abbrNum(value)}`} isHealthy={true} />;
            },
        },
    ];

    if (visibleColumns && visibleColumns.length > 0) {
        columns = columns.filter((c) => {
            return visibleColumns.includes(c.accessorKey);
        });
    }

    return columns;
};
