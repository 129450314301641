import api from "$services/api";
import logger from "$services/logger";
import { updateGlobalError } from "$stores/app-context";

const apiEmployees = async (query, callback, apiSettings) => {
    try {
        if (query && !query.match(/^(?:\S*\s){2}/)) {
            const response = await api.get(`/api/employees/all?query=${query}`, null, apiSettings);
            callback(response.employees.map((user) => ({ display: user.fullName, id: user.employeeId })));
        }
    } catch (e) {
        if (api.requestWasCanceled(e)) {
        }
        updateGlobalError("Unable to fetch employees");
        logger.error("Unable to fetch employees", e);
    }
};

export const fetchEmployees = (query, callback) => {
    const abortController = new AbortController();
    apiEmployees(query, callback, { signal: abortController.signal });
    return abortController;
};

export const extractUserTags = (str) => {
    const tagRegex = /@(.*?)\((\d+)\)/g;
    const tags = [];

    let match;
    while ((match = tagRegex.exec(str)) !== null) {
        const userTag = match[1].trim().replace(/\[|\]/g, ""); // Remove square brackets
        const employeeId = parseInt(match[2], 10);
        tags.push({ userTag, employeeId });
    }

    // Remove duplicates based on ID
    const uniqueTags = Array.from(new Set(tags.map((tag) => tag.employeeId))).map((id) => tags.find((tag) => tag.employeeId === id));

    return uniqueTags;
};

export const replaceMentionsWithLinks = (text) => {
    const mentionRegex = /@\[([^\]]+)\]\((\d+)\)/g;
    return text.replace(mentionRegex, "<a href='/team/$2'>$1</a>");
};
