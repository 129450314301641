import React from "react";

import ExistingProjectList from "./list";

import { useDocumentTitle } from "$utils/document-title";

const ExistingProjects = () => {
    useDocumentTitle("Existing Project List");

    return (
        <div>
            <ExistingProjectList />
        </div>
    );
};

export default ExistingProjects;
