export default {
    control: {},

    suggestions: {
        bottom: "calc(-100% - -45px)",
        top: "auto",
        list: {
            backgroundColor: "#03202d",
            border: "1px solid rgba(0,0,0,0.15)",
            fontSize: 14,
            maxHeight: "200px",
            overflowY: "auto",
        },
        item: {
            padding: "5px 15px",
            borderBottom: "1px solid rgba(0,0,0,0.15)",
            "&focused": {
                backgroundColor: "#1a7690",
            },
        },
    },
};
