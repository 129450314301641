import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import TableLoader from "$components/table/table-loader.js";

import { buildClientGroupsManagedColumns } from "./lib/columns.js";
import { mapToTableData } from "src/pages/projects/existing/list.js";
import { ClientGroupsManagedStore, fetchClientGroupsManaged, reset, setFilters, setHiddenColumns, setPageIndex, setPagingInfo, setPerPage, setSort } from "$stores/managed-client-groups-list.js";
import { getTerm } from "$stores/dictionary.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";
import { usePaginatedStoreWrapper } from "$utils/hooks/usePaginatedStore.js";

const ManagedClientGroups = () => {
    const { employeeId } = useParams();
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const filterMenu = AppContextStore.useState((f) => mapByKey(f.filterMenu, "apiKey") || {});
    const [clientGroupsManagedColumns, setClientGroupsManagedColumns] = useState([]);

    const { clientGroupsManaged, filters, globalFilterRanges, hiddenColumns, isLoading, isSortAsc, pageCount, pageIndex, pageSize, sort } = usePaginatedStoreWrapper(ClientGroupsManagedStore, {
        fetchList: fetchClientGroupsManaged,
        suppressGlobalFilters: true,
        overrideGlobalFilters: { clientPerson: employeeId },
        reset: reset,
        setPagingInfo: setPagingInfo,
    });

    useEffect(() => {
        setClientGroupsManagedColumns(buildClientGroupsManagedColumns({ filterOptions: filterMenu, globalFilterRanges }));
    }, [filterMenu, globalFilterRanges]);

    const rowClick = (row) => {
        navigate(`/client/${row.id}`);
        appInsights.trackEvent(
            { name: `Navigation - ${getTerm("parentClients")} List` },
            {
                label: row.id,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    const data = clientGroupsManaged.map(mapToTableData);

    return (
        <div>
            <h1>
                <span data-tooltip={`${getTerm("parentClients")} where the ${getTerm("teamMember").toLowerCase()} is the ${getTerm("parentCrm")}`}>
                    <em>{getTerm("parentClients")}</em> as {getTerm("parentCrm")}
                </span>
            </h1>
            <TableLoader
                allowFilter={true}
                columns={buildClientGroupsManagedColumns({ filterOptions: filterMenu, globalFilterRanges })}
                containerClass="table-client-groups-managed"
                data={data}
                filters={filters}
                hiddenColumns={hiddenColumns}
                isLoading={isLoading}
                isSortAsc={isSortAsc}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageCount={pageCount}
                setFilters={setFilters}
                setHiddenColumns={setHiddenColumns}
                setPageIndex={setPageIndex}
                setPerPage={setPerPage}
                setSort={setSort}
                sort={sort}
                onRowClick={rowClick}
            />
            <br />
        </div>
    );
};

export default ManagedClientGroups;
