import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import TableLoader from "$components/table/table-loader.js";

import { buildEmployeeProjectsColumns } from "./lib/columns.js";
import { EmployeeExistingProjectsStore, fetchEmployeeExistingProjects, reset, setFilters, setHiddenColumns, setPageIndex, setPagingInfo, setPerPage, setSort } from "$stores/employeeExistingProjects.js";
import { mapToTableData } from "src/pages/projects/existing/list.js";
import { AppContextStore } from "$stores/app-context.js";
import { mapByKey } from "$utils/mapping.js";
import { usePaginatedStoreWrapper } from "$utils/hooks/usePaginatedStore.js";
import { getTerm } from "$stores/dictionary.js";
import { EmployeeDetailsStore } from "$stores/employee-details.js";

const ExistingProjects = () => {
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const filterMenu = AppContextStore.useState((f) => mapByKey(f.filterMenu, "apiKey") || {});
    const { employeeDetails } = EmployeeDetailsStore.useState((s) => {
        return { employeeDetails: s.employeeDetails };
    });
    const { employeeExistingProjects, filters, hiddenColumns, isLoading, isSortAsc, pageCount, pageIndex, pageSize, sort } = usePaginatedStoreWrapper(EmployeeExistingProjectsStore, {
        suppressGlobalFilters: true,
        overrideGlobalFilters: { projectPerson: employeeDetails.id },
        fetchList: fetchEmployeeExistingProjects,
        reset: reset,
        setPagingInfo: setPagingInfo,
    });

    const [employeeProjectsColumns, setEmployeeProjectColumns] = useState([]);

    useEffect(() => {
        setEmployeeProjectColumns(buildEmployeeProjectsColumns({ filterOptions: filterMenu }));
    }, [filterMenu]);

    const rowClick = (row) => {
        navigate(`/project/${row.id}/existing`);
        appInsights.trackEvent(
            { name: `Navigation - ${getTerm("existingProjects")} List` },
            {
                label: row.id,
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    const data = employeeExistingProjects.map(mapToTableData);

    return (
        <TableLoader
            allowFilter={true}
            columns={employeeProjectsColumns}
            containerClass="project-list"
            data={data}
            filters={filters}
            hiddenColumns={hiddenColumns}
            isLoading={isLoading}
            isSortAsc={isSortAsc}
            onRowClick={rowClick}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setFilters={setFilters}
            setHiddenColumns={setHiddenColumns}
            setPageIndex={setPageIndex}
            setPerPage={setPerPage}
            setSort={setSort}
            sort={sort}
        />
    );
};

export default ExistingProjects;
